// import axios from 'axios'
import { defineStore } from 'pinia'

export const useGlobalStore = defineStore({
   id: 'global',
   state: () => ({
      cache: {
         version: 0,
         dirty: false,
         unsaved: false,
         created_at: new Date().getTime(),
         updated_at: new Date().getTime()
      },     

      online: navigator.onLine,
      bugtracker: {'id' : 0, 'name': '', 'aktiv': true, 'modal': false, 'anzahl': 0, bugtracks: []}, 
      breadcrumb: ['DRC-Datenverwaltung','','',''],
      mainmenu_items: null,
      usermenu_items:  null,
      profiledokumente_items:  null,
      profileverwaltung_items:  null,
      back_link: null,
      current_list: null,
      configs: null,
      columns: null,
      tooltips: null,
      settings: null,
      options: null,
      currentmainmenu: '',
      currentsubmenu: '',
      doktree:  null,
      orgatree:  null,
      loading: true,
      loadingMeineDaten: true,
      loadingMainMenu: true,
      loadingUserMenu: true,
      loadingDokumente: true,
      loadingVerwaltung: true,
      loadingOptions: true,
      pushback: {
         name: null,
         tabs_obj: null,
         tab_name: null,
         scroll_to_obj: null,
         scroll_to_pos: null,
         id: null,
      },
      scrollTo: null,
      cta: {
         step: null,
         action: null,
         id: null,
         forward : {
            routename: null,
            tabs_obj: null,
            tab_id: null,
            scrollto_id: null,
            scrollto_px: null,
         },
         back: {
            routename: null,
            tabs_obj: null,
            tab_id: null,
            scrollto_id: null,
            scrollto_px: null,
         }
      },
   }),
   getters: {

   },
   actions: {
      async getMainMenu(context) {
         await axios.get('/api/mainmenuitems', {
            headers: {}
         }).then((response) => {
            console.log("getMainmenu");
               this.mainmenu_items = response.data.data
               this.loadingMainMenu = false;
            })
      },
      async getUserMenu(context) {
         await axios.get('/api/usermenuitems', {
            headers: {}
         }).then((response) => {
            console.log("getUsermenu");
               this.usermenu_items = response.data.data
               this.loadingUserMenu = false
            })
      },
      async getProfileDokumenteItems(context) {
         await axios.get('/api/profiledokumenteitems', {
            headers: {}
         }).then((response) => {
               this.profiledokumente_items = response.data.data
            })
      },
      async getProfileVerwaltungItems(context) {
         await axios.get('/api/profileverwaltungitems', {
            headers: {}
         }).then((response) => {
               this.profileverwaltung_items = response.data.data
            })
      },
      async getDoktree(context) {
         await axios.get('/api/doktree', {
            headers: {}
         }).then((response) => {
            console.log("getDoktree");
               this.doktree = response.data.data
               this.loadingDokumente = false
            })
      },
      async getOrgatree(context) {
         await axios.get('/api/orgatree', {
            headers: {}
         }).then((response) => {
            console.log("getOrgatree");
               this.orgatree = response.data.data
               this.loadingVerwaltung = false;
            })

      },
      async getConfig(context) {
         await axios.get('/api/configs/', {
            headers: {}
         }).then((response) => {
               this.configs = response.data.data
            });
      },
      async getColumns(context) {
         await axios.get('/api/columns/', {
            headers: {}
         }).then((response) => {
               this.columns = response.data.data
            });
      },
      async getTooltips(context) {
         await axios.get('/api/tooltips',  {
            headers: {}
         }).then((response) => {
               this.tooltips = response.data.data
            })
      },
      async getSettings(context) {
         await axios.get('/api/settings', {
            headers: {}
         }).then((response) => {
               this.settings = response.data.data
            })
      },
      async getSetting(context) {
        
         await axios.get('/api/settings', {
            headers: {}
         }).then((response) => {
               this.settings = response.data.data
            })
      },
      async getOptions() {

         await axios.get('/api/options', {
            headers: {}
         })
            .then((response) => {
               console.log("getOptions");
               this.options = response.data
               this.loadingOptions = false

             });
      },
      async getBugtracks(id) {

         await axios.get('/api/bugtracks/' + this.bugtracker.id, {
            headers: {}
         })
            .then((response) => {
               console.log("getOptions");
               this.bugtracker.bugtracks = response.data.bugtracks
               this.bugtracker.anzahl = response.data.offen
           

             });
      },
      resetBugtracker() {
        
         this.bugtracker.id = 0
         this.bugtracker.name = ''
         this.bugtracker.aktiv = true
         this.bugtracker.modal = false
         this.bugtracker.anzahl = 0
         this.bugtracker.bugtracks = []
        
      },

       doPushback() {
         this.pushback.tabs_obj.selectTab("#ahnentafel");

     //  window.scrollTo({ top: 0, behavior: "smooth" });
      //    if (this.pushback.name) {
      //      router.push({ name: 'meine-zucht', params: { tab:this.pushback.tab, id:  this.pushback.id, scrollpos:this.pushback.scrollpos } })
      //    } else {

      //    }
       },

       jump_to_action() {
         this.pushback.tabs_obj.selectTab("#ahnentafel");
       },

       jump_back_from_action(){

       }
   },
   persist: true,
   // persist: {
   //    storage: sessionStorage,
   //    paths: ['someState'],
   //  },
})