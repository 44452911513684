import { createApp , ref } from 'vue'
import App from './App.vue'
import router from './router'

import localForage from "localforage";
localForage.config({
   driver: localForage.INDEXEDDB, // This force IndexedDB as the driver
})

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)


import './helpers/date2german'
import './assets/css/tailwind.css'
// import './assets/scss/sweetalert2/sweetalert2.scss'

import ClickOutside from '@/components/global/helpers/ClickOutsideDirective'

import axios from 'axios'
window.axios = axios;
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.baseURL = 'http://localhost:8000'


const app = createApp(App).use(router).use(pinia)

import { useGlobalStore } from '@/stores/global'
import { useAuthStore } from '@/stores/auth'
const global = useGlobalStore()
const auth = useAuthStore()

var app_mode = import.meta.env.MODE

console.log('env', import.meta.env)

import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
window.Pusher = Pusher
      
window.Echo = new Echo({
  broadcaster: 'reverb',
  key: import.meta.env.VITE_REVERB_APP_KEY,
  wsHost: import.meta.env.VITE_REVERB_HOST,
  wsPort: import.meta.env.VITE_REVERB_PORT,
  wssPort: import.meta.env.VITE_REVERB_PORT,
  forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
  enabledTransports: ['ws', 'wss'],
})
// app_mode = 'development';
switch (app_mode) {
   /** LIVE **/
   case 'production':
      app.provide('$_COOKIEDOMAIN', '.atbloom.de')
      axios.defaults.baseURL = 'https://drc-api.atbloom.de'
      break;

   /** DEVELOPMENT LOCAL */
   case 'development':
   default:
      app.provide('$_COOKIEDOMAIN', 'localhost')
      axios.defaults.baseURL = 'http://localhost:8000'

  
      

      break;
}

auth.me().then(() => {
   global.getOptions().then(() => {
      auth.loading = false

   })
})





import VueUploadComponent from 'vue-upload-component'
app.component('file-upload', VueUploadComponent)

app.directive('focus', {
   mounted(el, binding) { // When the bound element is inserted into the DOM...
      if (binding) el.focus() // Focus the element
   }
})

app.directive('click-outside', ClickOutside);



import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import './assets/css/snackbar.css';
app.use(SnackbarService);
app.component("vue3-snackbar", Vue3Snackbar);

import herz from './assets/svg/01_Herz.svg'
app.component('svg-herz', herz)
import hund from './assets/svg/02_Hund.svg'
app.component('svg-hund', hund)
import derverein from './assets/svg/03_Der Verein.svg'
app.component('svg-der-verein', derverein)
import Mitglieder from './assets/svg/04_Mitglieder.svg'
app.component('svg-mitglieder', Mitglieder)
import schloss from './assets/svg/05_Schloss.svg'
app.component('svg-schloss', schloss)
import rassen from './assets/svg/06_Rassen.svg'
app.component('svg-rassen', rassen)
import hobby from './assets/svg/07_Hobby.svg'
app.component('svg-hobby', hobby)
import zucht from './assets/svg/10_Zucht.svg'
app.component('svg-zucht', zucht)
import lupe from './assets/svg/09_Lupe.svg'
app.component('svg-lupe', lupe)
import gesundheit from './assets/svg/11_Gesundheit.svg'
app.component('svg-gesundheit', gesundheit)
import wiruberuns from './assets/svg/12_Wir_über_uns.svg'
app.component('svg-wir-uber-uns', wiruberuns)
import unserezieleaufgaben from './assets/svg/13_Unsere_Ziele - Aufgaben.svg'
app.component('svg-unsere-ziele-aufgaben', unserezieleaufgaben)
import vereinsleben from './assets/svg/14_Vereinsleben.svg'
app.component('svg-vereinsleben', vereinsleben)
import drcinside from './assets/svg/15_DRC_inside.svg'
app.component('svg-drc-inside', drcinside)
import termine from './assets/svg/16_Termine.svg'
app.component('svg-termine', termine)
import datenverwaltung from './assets/svg/17_Datenverwaltung.svg'
app.component('svg-datenverwaltung', datenverwaltung)
import wichtig from './assets/svg/18_Wichtig.svg'
app.component('svg-wichtig', wichtig)
import rassebeschreibung from './assets/svg/19_Rassebeschreibung.svg'
app.component('svg-rassebeschreibung', rassebeschreibung)
import intrassesstandart from './assets/svg/20_ Int. Rassesstandart.svg'
app.component('svg-int-rassesstandart', intrassesstandart)
import bildergalerie from './assets/svg/21_Bildergalerie.svg'
app.component('svg-bildergalerie', bildergalerie)
import pdf from './assets/svg/22_PDF.svg'
app.component('svg-pdf', pdf)
import tatze from './assets/svg/23_Tatze.svg'
app.component('svg-tatze', tatze)
import schauwesen from './assets/svg/24_Schauwesen.svg'
app.component('svg-schauwesen', schauwesen)
import dasleistungswesen from './assets/svg/25_Das Leistungswesen.svg'
app.component('svg-das-leistungswesen', dasleistungswesen)
import jagdarbeit from './assets/svg/26_Jagd. Arbeit.svg'
app.component('svg-jagd-arbeit', jagdarbeit)
import retrieverarbeitausserhalbdesdrc from './assets/svg/27_Retrieverarbeit außerhalb des DRC.svg'
app.component('svg-retrieverarbeit-ausserhalb-des-drc', retrieverarbeitausserhalbdesdrc)
import zuechter from './assets/svg/28_Zuechter.svg'
app.component('svg-zuechter', zuechter)
import mutter from './assets/svg/30_Mutter.svg'
app.component('svg-mutter', mutter)
import award from './assets/svg/31_Award.svg'
app.component('svg-award', award)
import map from './assets/svg/32_Map.svg'
app.component('svg-map', map)
import dokument from './assets/svg/33_Dokument.svg'
app.component('svg-dokument', dokument)
import dna from './assets/svg/34_DNA.svg'
app.component('svg-dna', dna)
import arzt from './assets/svg/35_Arzt.svg'
app.component('svg-arzt', arzt)
import doc from './assets/svg/36_DOC.svg'
app.component('svg-doc', doc)
import kontakt from './assets/svg/37_Kontakt.svg'
app.component('svg-kontakt', kontakt)
import absenden from './assets/svg/38_Absenden.svg'
app.component('svg-absenden', absenden)
import gehirn from './assets/svg/39_Gehirn.svg'
app.component('svg-gehirn', gehirn)
import organ from './assets/svg/41_Organ.svg'
app.component('svg-organ', organ)
import hr from './assets/svg/42_H-R.svg'
app.component('svg-h-r', hr)
import bakterie from './assets/svg/40_Bakterie.svg'
app.component('svg-bakterie', bakterie)
import vater from './assets/svg/29_Vater.svg'
app.component('svg-vater', vater)
import welpe from './assets/svg/44_Welpe.svg'
app.component('svg-welpe', welpe)
import ordnungen from './assets/svg/45_Ordnungen.svg'
app.component('svg-ordnungen', ordnungen)
import formulare from './assets/svg/46_Formulare.svg'
app.component('svg-formulare', formulare)
import gelenk from './assets/svg/43_Gelenk.svg'
app.component('svg-gelenk', gelenk)
import wurf from './assets/svg/47_Wurf.svg'
app.component('svg-wurf', wurf)
import ahnentafel from './assets/svg/48_Ahnentafel.svg'
app.component('svg-ahnentafel', ahnentafel)
import tabelle from './assets/svg/49_Tabelle.svg'
app.component('svg-tabelle', tabelle)
import keinzugang from './assets/svg/50_Kein Zugang.svg'
app.component('svg-kein-zugang', keinzugang)
import zuechtersuche from './assets/svg/51_Zuechtersuche.svg'
app.component('svg-zuechtersuche', zuechtersuche)
import deckdatum from './assets/svg/52_Deckdatum.svg'
app.component('svg-deckdatum', deckdatum)
import wurfdatum from './assets/svg/53_Wurfdatum.svg'
app.component('svg-wurfdatum', wurfdatum)
import web from './assets/svg/54_Web.svg'
app.component('svg-web', web)
import telefon from './assets/svg/55_Telefon.svg'
app.component('svg-telefon', telefon)
import mail from './assets/svg/56_Mail.svg'
app.component('svg-mail', mail)
import externerlink from './assets/svg/57_Externer Link.svg'
app.component('svg-externer-link', externerlink)
import glocke from './assets/svg/58_Glocke.svg'
app.component('svg-glocke', glocke)
import meinprofil from './assets/svg/59_Mein Profil.svg'
app.component('svg-mein-profil', meinprofil)
import meinehunde from './assets/svg/60_Meine Hunde.svg'
app.component('svg-meine-hunde', meinehunde)
import meinetermine from './assets/svg/61_Meine Termine.svg'
app.component('svg-meine-termine', meinetermine)
import meinedokumente from './assets/svg/62_Meine Dokumente.svg'
app.component('svg-meine-dokumente', meinedokumente)
import einstellungen from './assets/svg/63_Einstellungen.svg'
app.component('svg-einstellungen', einstellungen)
import bearbeiten from './assets/svg/64_Bearbeiten.svg'
app.component('svg-bearbeiten', bearbeiten)
import loeschen from './assets/svg/65_Loeschen.svg'
app.component('svg-loeschen', loeschen)
import aktualisieren from './assets/svg/66_Aktualisieren.svg'
app.component('svg-aktualisieren', aktualisieren)
import exportieren from './assets/svg/67_Exportieren.svg'
app.component('svg-exportieren', exportieren)
import filter from './assets/svg/68_Filter.svg'
app.component('svg-filter', filter)
import drucken from './assets/svg/70_Drucken.svg'
app.component('svg-drucken', drucken)
import spaltenansicht from './assets/svg/69_Spaltenansicht.svg'
app.component('svg-spaltenansicht', spaltenansicht)
import reset from './assets/svg/71_Reset.svg'
app.component('svg-reset', reset)
import upload from './assets/svg/73_Upload.svg'
app.component('svg-upload', upload)
import speicherncloud from './assets/svg/72_Speichern - Cloud.svg'
app.component('svg-speichern-cloud', speicherncloud)
import download from './assets/svg/74_Download.svg'
app.component('svg-download', download)
import meindashboard from './assets/svg/75_Mein Dashboard.svg'
app.component('svg-mein-dashboard', meindashboard)
import status from './assets/svg/76_Status.svg'
app.component('svg-status', status)
import personen from './assets/svg/77_Personen.svg'
app.component('svg-personen', personen)
import finanzenbank from './assets/svg/78_Finanzen - Bank.svg'
app.component('svg-finanzen-bank', finanzenbank)
import funktionstraeger from './assets/svg/79_Funktionstraeger.svg'
app.component('svg-funktionstraeger', funktionstraeger)
import exporte from './assets/svg/80_Exporte.svg'
app.component('svg-exporte', exporte)
import reportings from './assets/svg/81_Reportings.svg'
app.component('svg-reportings', reportings)
import administration from './assets/svg/82_Administration.svg'
app.component('svg-administration', administration)
import gentest from './assets/svg/84_Gentests.svg'
app.component('svg-gentest', gentest)
import augenuntersuchung from './assets/svg/85_Augenuntersuchungen.svg'
app.component('svg-augenuntersuchung', augenuntersuchung)
import retrieverarbeit from './assets/svg/83_retrieverarbeit.svg'
app.component('svg-retrieverarbeit', retrieverarbeit)
import hund2 from './assets/svg/86_Hund.svg'
app.component('svg-hund2', hund2)
import hundeakte from './assets/svg/hundeakte.svg'
app.component('svg-hundeakte', hundeakte)
import veranstaltungsakte from './assets/svg/veranstaltungsakte.svg'
app.component('svg-veranstaltungsakte', veranstaltungsakte)
import favorite from './assets/svg/favorite.svg'
app.component('svg-favorite', favorite)
import favoritehund from './assets/svg/favorite-hund.svg'
app.component('svg-favorite-hund', favoritehund)
import favoritezucht from './assets/svg/favorite-zucht.svg'
app.component('svg-favorite-zucht', favoritezucht)
import favoriteveranstaltung from './assets/svg/favorite-veranstaltung.svg'
app.component('svg-favorite-veranstaltung', favoriteveranstaltung)
import favoritesolid from './assets/svg/favorite-solid.svg'
app.component('svg-favorite-solid', favoritesolid)
import chevrondoubleright from './assets/svg/chevron-double-right.svg'
app.component('svg-chevron-double-right', chevrondoubleright)
import center from './assets/svg/center.svg'
app.component('svg-center', center)
import chevrondown from './assets/svg/chevron-down.svg'
app.component('svg-chevron-down', chevrondown)
import check from './assets/svg/check.svg'
app.component('svg-check', check)
import chevronleft from './assets/svg/chevron-left.svg'
app.component('svg-chevron-left', chevronleft)
import chevronright from './assets/svg/chevron-right.svg'
app.component('svg-chevron-right', chevronright)
import chevronup from './assets/svg/chevron-up.svg'
app.component('svg-chevron-up', chevronup)
import circlestencil from './assets/svg/circle-stencil.svg'
app.component('svg-circle-stencil', circlestencil)
import fliphorizontal from './assets/svg/flip-horizontal.svg'
app.component('svg-flip-horizontal', fliphorizontal)
import flipvertical from './assets/svg/flip-vertical.svg'
app.component('svg-flip-vertical', flipvertical)
import handler from './assets/svg/handler.svg'
app.component('svg-handler', handler)
import link from './assets/svg/link.svg'
app.component('svg-link', link)
import minus from './assets/svg/minus.svg'
app.component('svg-minus', minus)
import plus from './assets/svg/plus.svg'
app.component('svg-plus', plus)
import rectanglestencil from './assets/svg/rectangle-stencil.svg'
app.component('svg-rectangle-stencil', rectanglestencil)
import resizehorizontal from './assets/svg/resize-horizontal.svg'
app.component('svg-resize-horizontal', resizehorizontal)
import resizemaximize from './assets/svg/resize-maximize.svg'
app.component('svg-resize-maximize', resizemaximize)
import rotateleft from './assets/svg/rotate-left.svg'
app.component('svg-rotate-left', rotateleft)
import rotateright from './assets/svg/rotate-right.svg'
app.component('svg-rotate-right', rotateright)
import checked from './assets/svg/checked.svg'
app.component('svg-checked', checked)
import resizereduce from './assets/svg/resize-reduce.svg'
app.component('svg-resize-reduce', resizereduce)
import resizevertical from './assets/svg/resize-vertical.svg'
app.component('svg-resize-vertical', resizevertical)
import resize from './assets/svg/resize.svg'
app.component('svg-resize', resize)
import x from './assets/svg/x.svg'
app.component('svg-x', x)
import zoomin from './assets/svg/zoom-in.svg'
app.component('svg-zoom-in', zoomin)
import zoomout from './assets/svg/zoom-out.svg'
app.component('svg-zoom-out', zoomout)
import upload2 from './assets/svg/upload-2.svg'
app.component('svg-upload-2', upload2)
import bars2 from './assets/svg/bars-2.svg'
app.component('svg-bars-2', bars2)
import barsarrowdown from './assets/svg/bars-arrow-down.svg'
app.component('svg-bars-arrow-down', barsarrowdown)
import barsarrowup from './assets/svg/bars-arrow-up.svg'
app.component('svg-bars-arrow-up', barsarrowup)
import chevronupdown from './assets/svg/chevron-up-down.svg'
app.component('svg-chevron-up-down', chevronupdown)
import ellipsishorizontal from './assets/svg/ellipsis-horizontal.svg'
app.component('svg-ellipsis-horizontal', ellipsishorizontal)
import ellipsisvertical from './assets/svg/ellipsis-vertical.svg'
app.component('svg-ellipsis-vertical', ellipsisvertical)
import eye from './assets/svg/eye.svg'
app.component('svg-eye', eye)
import eyeslash from './assets/svg/eye-slash.svg'
app.component('svg-eye-slash', eyeslash)
import userminus from './assets/svg/user-minus.svg'
app.component('svg-user-minus', userminus)
import userplus from './assets/svg/user-plus.svg'
app.component('svg-user-plus', userplus)
import welpen from './assets/svg/08_Welpen.svg'
app.component('svg-welpen', welpen)
import geldscheine from './assets/svg/geldscheine.svg'
app.component('svg-geldscheine', geldscheine)
import chatbubblevoll from './assets/svg/chatbubble-voll.svg'
app.component('svg-chatbubble-voll', chatbubblevoll)
import chatbubbleleer from './assets/svg/chatbubble-leer.svg'
app.component('svg-chatbubble-leer', chatbubbleleer)
import handstop from './assets/svg/hand-stop.svg'
app.component('svg-hand-stop', handstop)
import kreditkarte from './assets/svg/kreditkarte.svg'
app.component('svg-kreditkarte', kreditkarte)
import pokal from './assets/svg/pokal.svg'
app.component('svg-pokal', pokal)
import megaphone from './assets/svg/megaphone.svg'
app.component('svg-megaphone', megaphone)
import schlosszu from './assets/svg/schloss-zu.svg'
app.component('svg-schloss-zu', schlosszu)
import schlossoffen from './assets/svg/schloss-offen.svg'
app.component('svg-schloss-offen', schlossoffen)
import atsymbol from './assets/svg/at-symbol.svg'
app.component('svg-at-symbol', atsymbol)

import gebiss from './assets/svg/gebiss.svg'
app.component('svg-gebiss', gebiss)
import zahn from './assets/svg/zahn2.svg'
app.component('svg-zahn', zahn)
import anmeldung from './assets/svg/90_Anmeldung.svg'
app.component('svg-anmeldung', anmeldung)

import IconLogo from './assets/icons/png/logo.png'
app.component('icon-logo', IconLogo)

import ControlArrow from './assets/controls/svg/arrow-ico.svg'
app.component('control-arrow', ControlArrow)

import chevrondoubledown from './assets/svg/chevron-double-down.svg'
app.component('svg-chevron-double-down', chevrondoubledown)
import chevrondoubleup from './assets/svg/chevron-double-up.svg'
app.component('svg-chevron-double-up', chevrondoubleup)
import clock from './assets/svg/clock.svg'
app.component('svg-clock', clock)

import statsbar from './assets/svg/stats-bar.svg'
app.component('svg-stats-bar', statsbar)
import statspie from './assets/svg/stats-pie.svg'
app.component('svg-stats-pie', statspie)

import zwingerakte from './assets/svg/zwingerakte.svg'
app.component('svg-zwingerakte', zwingerakte)
import wurfakte from './assets/svg/wurfakte.svg'
app.component('svg-wurfakte', wurfakte)
import favoriteperson from './assets/svg/favorite-person.svg'
app.component('svg-favorite-person', favoriteperson)

import email from './assets/svg/88_EMail.svg'
app.component('svg-email', email )
import euro from './assets/svg/89_Euro.svg'
app.component('svg-euro', euro )
import frage from './assets/svg/91_Frage.svg'
app.component('svg-frage', frage )
import hundnotok from './assets/svg/93_Hund_not_ok.svg'
app.component('svg-hund-not-ok', hundnotok )
import hundok from './assets/svg/92_Hund_ok.svg'
app.component('svg-hund-ok', hundok )
import info from './assets/svg/94_Info.svg'
app.component('svg-info', info )
import jagdscheinja from './assets/svg/95_Jagdschein_ja.svg'
app.component('svg-jagdschein-ja', jagdscheinja )
import jagdscheinnein from './assets/svg/96_Jagdschein_nein.svg'
app.component('svg-jagdschein-nein', jagdscheinnein )
import teilnehmerok from './assets/svg/97_Teilnehmer_ok.svg'
app.component('svg-teilnehmer-ok', teilnehmerok )
import teilnehmernotok from './assets/svg/98_Teilnehmer_not_ok.svg'
app.component('svg-teilnehmer-not-ok', teilnehmernotok )

import cursordragdrop from './assets/svg/cursor-drag-drop.svg'
app.component('svg-cursor-drag-drop', cursordragdrop )
import dragdrop from './assets/svg/drag-drop.svg'
app.component('svg-drag-drop', dragdrop )

import decrease from './assets/svg/decrease.svg'
app.component('svg-decrease', decrease )
import fullscreen from './assets/svg/fullscreen.svg'
app.component('svg-fullscreen', fullscreen )
import savefloppy from './assets/svg/save-floppy.svg'
app.component('svg-save-floppy', savefloppy )
import attachfile from './assets/svg/attach-file.svg'
app.component('svg-attach-file', attachfile )

import daumenhoch from './assets/svg/daumen-hoch.svg'
app.component('svg-daumen-hoch', daumenhoch )
import daumenrunter from './assets/svg/daumen-runter.svg'
app.component('svg-daumen-runter', daumenrunter )
import fragezeichen from './assets/svg/fragezeichen.svg'
app.component('svg-fragezeichen', fragezeichen )

import formularchecked from './assets/svg/99_Formular_checked.svg'
app.component('svg-formular-checked', formularchecked )
import formularunchecked from './assets/svg/100_Formular_unchecked.svg'
app.component('svg-formular-unchecked', formularunchecked )

import send from './assets/svg/send.svg'
app.component('svg-send', send )

import FloatingVue from 'floating-vue'

app.use(FloatingVue)


app.mount('#app')
