<template>
  <div class="w-full h-screen font-sans bg-cover bg-landscape">
    <div class="container flex items-center justify-center flex-1 h-full mx-auto">
      <div class="w-full max-w-lg">
        <div class="leading-loose">
          <div
            class="max-w-sm px-2 pb-10 m-auto rounded-lg shadow-xl bg-drc-gruen-300 bg-opacity-80"
          >
            <div class="flex items-center justify-start mb-10 text-xl text-white">
              <img
                src="@/assets/icons/png/logo.png"
                class="w-20 mr-4"
              />DRC-Datenverwaltung<br />startet ...
            </div>
            <div class="flex flex-col items-start w-full text-white text-lg font-normal">
              <div class="h-20 flex items-center w-full">
                <div class="w-20 flex justify-center items-center">
                  <div v-if="!auth.authenticated" class="graphic"></div>
                  <svg-checked v-else class="w-12 text-drc-gruen-500" />
                </div>
                <span :class="{ 'text-white font-bold': auth.authenticated }"
                  >Authentifizerung <br />{{ auth.name }}</span
                >
              </div>
              <div class="h-10 flex items-center">
                <div class="w-20 flex justify-center items-center">
                  <div v-if="global.loadingMeineDaten" class="graphic"></div>
                  <svg-checked v-else class="w-12 text-drc-gruen-500" />
                </div>
                <span :class="{ 'text-white font-bold': !global.loadingMeineDaten }"
                  >Lade persönliche Daten</span
                >
              </div>
              <div class="h-10 flex items-center">
                <div class="w-20 flex justify-center items-center">
                  <div v-if="global.loadingOptions" class="graphic"></div>
                  <svg-checked v-else class="w-12 text-drc-gruen-500" />
                </div>
                <span :class="{ 'text-white font-bold': !global.loadingOptions }"
                  >Lade Optionen</span
                >
              </div>
              <div class="h-10 flex items-center">
                <div class="w-20 flex justify-center items-center">
                  <div v-if="global.loadingMainMenu" class="graphic"></div>
                  <svg-checked v-else class="w-12 text-drc-gruen-500" />
                </div>
                <span :class="{ 'text-white font-bold': !global.loadingMainMenu }"
                  >Lade Hauptmenü</span
                >
              </div>
              <div class="h-10 flex items-center">
                <div class="w-20 flex justify-center items-center">
                  <div v-if="global.loadingUserMenu" class="graphic"></div>
                  <svg-checked v-else class="w-12 text-drc-gruen-500" />
                </div>
                <span :class="{ 'text-white font-bold': !global.loadingUserMenu }"
                  >Lade Usermenü</span
                >
              </div>
              <div class="h-10 flex items-center">
                <div class="w-20 flex justify-center items-center">
                  <div v-if="global.loadingDokumente" class="graphic"></div>
                  <svg-checked v-else class="w-12 text-drc-gruen-500" />
                </div>
                <span :class="{ 'text-white font-bold': !global.loadingDokumente }"
                  >Lade Dokumente</span
                >
              </div>
              <div class="h-10 flex items-center">
                <div class="w-20 flex justify-center items-center">
                  <div v-if="global.loadingVerwaltung" class="graphic"></div>
                  <svg-checked v-else class="w-12 text-drc-gruen-500" />
                </div>
                <span :class="{ 'text-white font-bold': !global.loadingVerwaltung }"
                  >Lade Verwaltung</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div v-if="loading" class="loaderbar">
    <div class="loader">
   
    </div>
  </div> -->
</template>

<script setup>
import { useGlobalStore } from "@/stores/global";
const global = useGlobalStore();

import { useAuthStore } from "@/stores/auth";
const auth = useAuthStore();

const props = defineProps({
  msg: String,
  loading: Boolean,
});
</script>

<style lang="scss">
.bg-landscape {
  background-image: url("../../assets/img/login_golden-retriever.jpg");
}

.graphic {
  position: relative;
  z-index: 10000;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: #269d2e;
  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

  &:before {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #b2c902;
    -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }

  &:after {
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #ffb100;
    -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(0deg); /* IE 9 */
      transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
    }
    100% {
      -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(360deg); /* IE 9 */
      transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(0deg); /* IE 9 */
      transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
    }
    100% {
      -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(360deg); /* IE 9 */
      transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
    }
  }
}
</style>
